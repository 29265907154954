@font-face {
  font-family: 'Didot';
  src: local('Didot'), url('./fonts/Didot_Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Didot-bold';
  src: local('Didot'), url('./fonts/Didot_LT_Std_Bold.otf') format('truetype');
  font-style: bold;
  font-weight: 700;
}

@font-face {
  font-family: 'Didot-italic';
  src: local('Didot'), url('./fonts/Didot_Bold_Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: 'Didot', serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.submenu-item {
  cursor: pointer;
  color: white;
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.submenu-item:hover {
  cursor: pointer;
  color: yellow;
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.menu-item:hover {
  cursor: pointer;
  color: yellow;
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.hotspot-button {
  background: rgba(128, 128, 128, 0.65);
  backdrop-filter: blur(5px);
  font-size: 10px;
  color: #000;
  min-width: 100px;
  padding: '12px';
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: none;
  box-shadow: #00000050 0px 0px 10px;
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.hotspot-button:hover{
  cursor: pointer;
  background: rgba(128, 128, 128, 0.9);
  transition:cubic-bezier(1, 1, 1, 1) 0.3s;
}

.hotspot-button[data-isOpen="true"] {
  width: 400px;
  height: 200px;
}

.child {
  width: 40px;
  height: 40px;
  background: #f107a3;
  border-radius: 50%;
}

.close-button:hover{
  cursor: 'pointer !important',
}

.image-container {
  margin: 5rem 0;
  width: 60vw;
  height: 60vw;
  overflow: hidden;
  /* background-color: pink; */
}

img {
  width: 100%;
  scale: 1.3;
}

.section1{
  background-color: rgb(255, 255, 255, 1);
  font-family: 'Didot';
}
